'use client';

import { buildAppUrl, useBuildAppUrl } from '@/lib/utils';
import { FC } from 'react';
import styled from 'styled-components';

const StyledFrame = styled.iframe((_) => ({
  border: 'none',
  height: '100%',
}));

type AppFrameProps = {
  app: Parameters<typeof buildAppUrl>[0];
  path: Parameters<typeof buildAppUrl>[1];
  params?: Parameters<typeof buildAppUrl>[2];
  queryParams?: Parameters<typeof buildAppUrl>[3];
};

export const AppFrame: FC<AppFrameProps> = ({
  app,
  path,
  params = null,
  queryParams = null,
}) => {
  const url = useBuildAppUrl(app, path, params, queryParams);

  if (!url) {
    return null;
  }

  return <StyledFrame src={url} referrerPolicy="origin" />;
};
